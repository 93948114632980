import { IonContent, IonHeader, IonPage, IonList, IonLabel, IonItem, IonTitle, IonToolbar, IonGrid, IonRow, IonSearchbar, IonCol, IonButtons, IonBackButton, IonListHeader, IonCard, IonCardHeader, IonCardSubtitle, IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import { getCategories, getProductsBySearch, getCategoryImages, removeSubcategory } from '../ServerProvider';
import Product from './Product';
import CartButton from './Cart';
import SetName from './SetName';
import CategoryImage from './CategoryImage';
import { list } from 'ionicons/icons';
import Reorder from './Reorder';
import ReorderSub from './ReorderSub';

class Products extends React.Component<{warehouse: boolean, match: any}, {categories: any[], products: any[], images: string[], modalOpen: boolean, searchVal: string}> {
  category = ""
  subcategory = ""

  constructor(props: any) { 
    super(props);
    this.state = {
      categories: [],
      products: [],
      images: [],
      modalOpen: false,
      searchVal: ""
    }

    const params = this.props.match.params

    this.category = params.category
    if (params.category) {
      this.category = decodeURIComponent(params.category)
    }

    this.subcategory = params.subcategory
    if (params.subcategory) {
      this.subcategory = decodeURIComponent(params.subcategory)
    }

    getCategories().then((categories) => {
      for (let i = 0; i < categories.length; i++) {
        categories[i].number = i
      }
      categories.sort((a, b) => (+(a.name as string).split(" ")[0].split("#")[1] as number) - (+(b.name as string).split(" ")[0].split("#")[1]))
      this.setState({categories})
      if (params.category) {
        getCategoryImages(this.category).then(images => this.setState({images}))
      } else {
        getCategoryImages().then(images => this.setState({images}))
      }
    }).catch(null)

    if (params.subcategory) {
      getProductsBySearch(this.category, this.subcategory, null, (product) => {
        this.state.products.push(product)
        this.setState({ products: this.state.products })
      })
    }
    console.log(this.props)
  }

  wurl() {
    return this.props.warehouse ? "warehouse" : "field"
  }

  onSearchBar(val: string) {
    this.setState({products: []})
    if (val.length > 3) {
      getProductsBySearch(this.category, this.subcategory, val, (product) => {
        if (product.val === this.state.searchVal) {
         this.state.products.push(product)
         this.setState({ products: this.state.products })
        }
      })
    }
  } 

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle color="primary">{this.subcategory ? this.subcategory : (this.category ? this.category: "Products")}</IonTitle>
            
            <IonButtons slot="primary">
              <IconButton />&ensp;
              {this.props.warehouse && (!this.subcategory && this.category) ? <CategoryImage category={this.category} /> : null}
              {this.props.warehouse && (!this.subcategory && this.category) ? <ReorderSub category={this.category} subcategories={
                this.state.categories.find(cat => cat.name === this.category) ? this.state.categories.find(cat => cat.name === this.category).subcategories : []
               } /> : null}
              {this.props.warehouse && (this.subcategory) ? <IonButton onClick={() => removeSubcategory(this.category, this.subcategory)} fill="clear" slot="end">Remove Subcategory</IonButton> : null}
              {this.props.warehouse && (this.subcategory) ? <Reorder products={this.state.products} /> : null}
              <CartButton />&ensp;
              <SetName />&ensp;
              <IonButton routerLink={`/${this.wurl()}/orders`}>
                <IonIcon icon={list} slot="start" />
                Orders
              </IonButton>
              
            </IonButtons>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar onIonChange={(event) => {this.onSearchBar(event.detail.value ? (event.detail.value as string): ""); this.setState({searchVal: event.detail.value ? (event.detail.value as string): ""})}}></IonSearchbar>
          <IonGrid>
          {this.props.warehouse ? <IonItem routerLink={`/${this.wurl()}/addProduct`}>
                  <IonLabel>Add Product</IonLabel>
                </IonItem> : null}
              <IonRow>
                {this.state.products.map((product, i) => {
                  return <IonCol key={i} sizeSm="6" sizeMd="4" sizeLg="2"><Product product={product} warehouse={this.props.warehouse} qcHandler={null} readOnly={false}/></IonCol>
                })}
              </IonRow>
              <IonRow>
                {this.subcategory ? null : <IonListHeader>{this.category && !this.subcategory ? "Subcategories": "Categories"}</IonListHeader>}
                
                {(() => {
                  const params = this.props.match.params
                  if (!params.subcategory) {
                    if (params.category) {
                      for (let category of this.state.categories) {
                        if (category.name === this.category) {
                          return category.subcategories.map((subcategory: any, i: number) => {
                            return <IonCol sizeSm="6" sizeMd="4" sizeLg="2" key={i}>
                              <IonCard routerLink={`/${this.wurl()}/products/${encodeURIComponent(params.category)}/${encodeURIComponent(subcategory)}`}>
                                {this.state.images.length > i ? <img src={"data:image;base64,"+this.state.images[i]} alt={subcategory} /> : <img src="" alt="" />}
                                <IonCardHeader>
                                  <IonCardSubtitle>{subcategory}</IonCardSubtitle>
                                </IonCardHeader>
                               
                              </IonCard>
                            </IonCol>
                          })
                        }
                      }
                    } else {
                      return this.state.categories.map((category: any, i: number) => {

                        return <IonCol sizeSm="6" sizeMd="4" sizeLg="2" key={i}>
                              <IonCard routerLink={`/${this.wurl()}/products/${encodeURIComponent(category.name)}`}>
                                {this.state.images.length > i ? <img src={"data:image;base64,"+this.state.images[category.number]} alt={category.name} /> : <img src="" alt="" />}
                                <IonCardHeader ><IonCardSubtitle>{category.name}</IonCardSubtitle></IonCardHeader>
                                
                              </IonCard>
                            </IonCol>
                      })
                    }
                  }
                })()}
              </IonRow>
          </IonGrid>
          <IonList>
            
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default Products;

export const IconButton = () => (<IonButton routerLink={`/`}>
  <img style={{width: "70pt"}} slot="icon-only" alt="logo" src="/assets/logored.svg" />
</IonButton>)
