import { IonContent, IonPage, IonList, IonLabel, IonItem, IonIcon, IonCard, IonCardContent } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import *  as msal from '@azure/msal-browser';
import './Home.css';
import { auth } from '../ServerProvider';

const Home: React.FC = () => {
  const [isDark, setIsDark] = useState(false);
  const [isEnabled, setEnabled] = useState(false);
  const [isFull, setFull] = useState(localStorage.getItem("full") ? true : false);

  useEffect(() => {
      // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd: any) {
      setIsDark(shouldAdd)
    }

    if (!localStorage.getItem("auth")) {
      const msalConfig = {
        auth: {
          clientId: "1cd1d31b-ba07-4c90-ba22-98978795ecbe",
          authority: "https://login.microsoftonline.com/eec44b29-e7d2-4984-abcf-fda87cded70e"
        }
      }
    
      const msalInstance = new msal.PublicClientApplication(msalConfig);
    
      msalInstance.loginPopup({scopes: ["user.read"]})
    
      msalInstance.handleRedirectPromise().then((e) => {
        const account = msalInstance.getAllAccounts()[0]
        msalInstance.acquireTokenPopup({scopes: ["user.read"], account: msalInstance.getAllAccounts()[0]}).then((a) => {
          console.log(a.accessToken)
          auth(a.accessToken).then(auth => {
            localStorage.setItem("auth", auth.auth)
            localStorage.setItem("name", account.name ? account.name : "")
            localStorage.setItem("full", auth.full)
            setEnabled(true)
            setFull(auth.full)
          })
        })
      })
    } else {
      setEnabled(true)
    }
  }, [])
  return (
    <IonPage>
      <IonContent className="nonebg">
        <IonCard className="noshadow" style={{width: "50%", left: "25%", top: "25%", position: "absolute"}} >
          <img style={{width: "50%", margin: "0 0 0 25%"}} src={isDark ? "/assets/logodark2.svg" : "/assets/logo2.svg"} alt="logo" />
          { isEnabled ?
          <IonCardContent>
            <IonList className="none">
              <IonItem routerLink="/field" onClick={() => {
                window.localStorage.setItem('mode', 'field')
              }}>
                <IonIcon src="/assets/field.svg" slot="start" />
                <IonLabel color={isDark ? "primary" : "white"} style={isDark ? {} : {color: "white"}}>Field</IonLabel>
              </IonItem>
              { isFull ? 
              <IonItem routerLink="/warehouse" onClick={() => {
                window.localStorage.setItem('mode', 'warehouse')
              }}>
                <IonIcon src="/assets/warehouse.svg" slot="start" />
                <IonLabel color="primary" style={isDark ? {} : {color: "white"}}>Warehouse</IonLabel>

              </IonItem>
              : null}
            </IonList>
          </IonCardContent> : null
          }
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Home;
